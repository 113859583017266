

const StepsPage = () => {

    return (
        <section className="page-section">
            <div className="container position-relative">

                <div className="row mb-80 mb-sm-60">
                    <div className="col-md-8 col-lg-6">
                        <h2 className="section-title mb-0">Three Simple Steps to Succeed with Renovative Lab</h2>
                    </div>
                    <div className="col-lg-6 pt-20 pt-md-30">
                        <p className="text-gray mb-0">
                            At Renovative Lab, we follow a streamlined process to ensure the success of your
                            projects. Our steps include thorough research, meticulous implementation, and
                            high-quality production.
                        </p>
                    </div>
                </div>

                {/*Steps Grid */}
                <div className="row mt-n30">

                    {/*Gradient Settings for SVG Icons */}
                    <svg className="visually-hidden" aria-hidden="true">
                        <linearGradient id="gradient-horizontal">
                            <stop offset="0%" stop-color="var(--color-primary-4)" />
                            <stop offset="33%" stop-color="var(--color-primary-3)" />
                            <stop offset="67%" stop-color="var(--color-primary-2)" />
                            <stop offset="100%" stop-color="var(--color-primary-1)" />
                        </linearGradient>
                    </svg>
                    {/*End Gradient Settings for SVG Icons */}

                    {/*Steps Item */}
                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch mt-30 wow fadeIn"
                        data-wow-duration="1.2s" data-wow-delay="0">
                        <div className="features-2-item pt-sm-40 pb-sm-40">

                            <div className="features-2-label">
                                Step 1
                            </div>

                            <div className="features-2-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" />
                                </svg>
                            </div>

                            <h4 className="features-2-title">
                                Research
                            </h4>

                            <div className="features-2-descr">
                                Comprehensive research to understand the needs and challenges of your project,
                                ensuring a tailored approach for the best outcomes.
                            </div>

                        </div>
                    </div>
                    {/*End Steps Items */}

                    {/*Steps Item */}
                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch mt-30 wow fadeIn"
                        data-wow-duration="1.2s" data-wow-delay="0.15s">
                        <div className="features-2-item pt-sm-40 pb-sm-40">

                            <div className="features-2-label">
                                Step 2
                            </div>

                            <div className="features-2-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M6.676 9.18c-1.426-.009-3.217.764-4.583 2.13-.521.521-.979 1.129-1.333 1.812 1.232-.933 2.547-1.225 4.086-.361.453-1.199 1.056-2.418 1.83-3.581zm8.154 8.143c-1.264.826-2.506 1.422-3.581 1.842.863 1.54.571 2.853-.361 4.085.684-.353 1.291-.812 1.812-1.334 1.37-1.369 2.144-3.165 2.13-4.593zm5.127-13.288c-.344-.024-.681-.035-1.011-.035-7.169 0-11.249 5.465-12.733 9.86l3.939 3.94c4.525-1.62 9.848-5.549 9.848-12.642 0-.366-.014-.74-.043-1.123zm-8.24 8.258c-.326-.325-.326-.853 0-1.178.325-.326.853-.326 1.178 0 .326.326.326.853 0 1.178-.326.326-.853.326-1.178 0zm2.356-2.356c-.651-.65-.651-1.706 0-2.357s1.706-.651 2.356 0c.651.651.651 1.706 0 2.357-.65.65-1.704.65-2.356 0zm-12.432 10.378l-.755-.755 4.34-4.323.755.755-4.34 4.323zm4.149 1.547l-.755-.755 3.029-3.054.755.755-3.029 3.054zm-5.035 2.138l-.755-.755 5.373-5.364.756.755-5.374 5.364zm21.083-14.291c-.188.618-.673 1.102-1.291 1.291.618.188 1.103.672 1.291 1.291.189-.619.673-1.103 1.291-1.291-.618-.188-1.102-.672-1.291-1.291zm-14.655-6.504c-.247.81-.881 1.443-1.69 1.69.81.247 1.443.881 1.69 1.69.248-.809.881-1.443 1.69-1.69-.81-.247-1.442-.88-1.69-1.69zm-1.827-3.205c-.199.649-.706 1.157-1.356 1.355.65.199 1.157.707 1.356 1.355.198-.649.706-1.157 1.354-1.355-.648-.198-1.155-.706-1.354-1.355zm5.387 0c-.316 1.035-1.127 1.846-2.163 2.163 1.036.316 1.847 1.126 2.163 2.163.316-1.036 1.127-1.846 2.162-2.163-1.035-.317-1.845-1.128-2.162-2.163zm11.095 13.64c-.316 1.036-1.127 1.846-2.163 2.163 1.036.316 1.847 1.162 2.163 2.197.316-1.036 1.127-1.881 2.162-2.197-1.035-.317-1.846-1.127-2.162-2.163z" />
                                </svg>
                            </div>

                            <h4 className="features-2-title">
                                Implementation
                            </h4>

                            <div className="features-2-descr">
                                Executing the project plan with precision, leveraging the latest technologies and
                                methodologies to ensure success.
                            </div>

                        </div>
                    </div>
                    {/*End Steps Items */}

                    {/*Steps Item */}
                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch mt-30 wow fadeIn"
                        data-wow-duration="1.2s" data-wow-delay="0.3s">
                        <div className="features-2-item pt-sm-40 pb-sm-40">

                            <div className="features-2-label">
                                Step 3
                            </div>

                            <div className="features-2-icon">
                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M24 20h-3v4l-5.333-4h-7.667v-4h2v2h6.333l2.667 2v-2h3v-8.001h-2v-2h4v12.001zm-6-6h-9.667l-5.333 4v-4h-3v-14.001h18v14.001zm-9-4.084h-5v1.084h5v-1.084zm5-2.916h-10v1h10v-1zm0-3h-10v1h10v-1z" />
                                </svg>
                            </div>

                            <h4 className="features-2-title">
                                Production
                            </h4>

                            <div className="features-2-descr">
                                Delivering high-quality results that meet your needs and exceed your expectations,
                                ready for market or deployment.
                            </div>

                        </div>
                    </div>
                    {/*End Steps Items */}

                </div>
                {/*End Steps Grid */}

            </div>
        </section>
    );

};

export default StepsPage;