import React from 'react';

const ServicePage = () => {
    return (

        <section className="page-section" id="services">
            <div className="container position-relative">

                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">

                        <h2 className="section-caption-gradient mb-20 mb-xs-10">Our Services</h2>

                        <div className="section-line-gradient mx-auto mb-20"></div>

                        <h3 className="section-title mb-40 mb-sm-30">Innovative Tech Solutions for Sustainability</h3>

                        <p className="text-gray mb-80 mb-sm-60">
                            Our services focus on sustainable technology consulting, eco-friendly software
                            development, and pioneering green tech innovations.
                        </p>

                    </div>
                </div>

                <div className="row wow fadeInUp">



                    {/*Services Tabs */}
                    <div className="col-lg-5 col-xl-4 mb-md-50 mb-sm-30">
                        <ul className="nav nav-tabs services-7-tabs" role="tablist">
                            <li>
                                <a href="#services-item-1a" className="active" aria-controls="services-item-1a"
                                    role="tab" aria-selected="true" data-bs-toggle="tab">
                                    <h4 className="services-7-title">Sustainable IT Consulting</h4>
                                    <div className="services-7-text">
                                        Optimizing energy-efficient IT systems for greener operations.
                                    </div>
                                    <div className="services-7-arrow"><i className="mi-arrow-right size-24"></i></div>
                                </a>
                            </li>
                            <li>
                                <a href="#services-item-2a" aria-controls="services-item-2a" role="tab"
                                    aria-selected="false" data-bs-toggle="tab">
                                    <h4 className="services-7-title">Eco-Friendly Software Development</h4>
                                    <div className="services-7-text">
                                        Building low-energy, resource-efficient software solutions.
                                    </div>
                                    <div className="services-7-arrow"><i className="mi-arrow-right size-24"></i></div>
                                </a>
                            </li>
                            <li>
                                <a href="#services-item-3a" aria-controls="services-item-3a" role="tab"
                                    aria-selected="false" data-bs-toggle="tab">
                                    <h4 className="services-7-title">Green Technology Integration
                                    </h4>
                                    <div className="services-7-text">
                                        Integrating renewable energy tech for sustainable business growth.
                                    </div>
                                    <div className="services-7-arrow"><i className="mi-arrow-right size-24"></i></div>
                                </a>
                            </li>
                            <li>
                                <a href="#services-item-4a" aria-controls="services-item-4a" role="tab"
                                    aria-selected="false" data-bs-toggle="tab">
                                    <h4 className="services-7-title">Digital Sustainability Strategies</h4>
                                    <div className="services-7-text">
                                        Crafting eco-conscious digital strategies for business success.
                                    </div>
                                    <div className="services-7-arrow"><i className="mi-arrow-right size-24"></i></div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/*End Services Tabs */}

                    {/*Services Images */}
                    <div className="col-lg-7 offset-xl-1">
                        <div className="tab-content position-sticky">

                            {/*Tab Image */}
                            <div className="tab-pane show fade active" id="services-item-1a" role="tabpanel">
                                <div className="services-7-content">

                                    <div className="services-7-image">
                                        <img src="assets/images/services/s_m_1.jpg" alt="Sustainable Tech Consulting" />
                                    </div>

                                    {/*<div className="small text-gray text-center mt-3">
                                    Image Source: <a href="https://icons8.com/illustrations" rel="noopener nofollow" target="_blank">Icons8</a>
                                </div> */}

                                </div>
                            </div>
                            {/*End Tab Image */}

                            {/*Tab Image */}
                            <div className="tab-pane show fade" id="services-item-2a" role="tabpanel">
                                <div className="services-7-content">

                                    <div className="services-7-image">
                                        <img src="assets/images/services/2.jpg" alt="Eco-friendly Software Development" />
                                    </div>

                                    {/*<div className="small text-gray text-center mt-3">
                                    Image Source: <a href="https://icons8.com/illustrations" rel="noopener nofollow" target="_blank">Icons8</a>
                                </div> */}

                                </div>
                            </div>
                            {/*End Tab Image */}

                            {/*Tab Image */}
                            <div className="tab-pane show fade" id="services-item-3a" role="tabpanel">
                                <div className="services-7-content">

                                    <div className="services-7-image">
                                        <img src="assets/images/services/3.jpg" alt="Green Tech Innovations" />
                                    </div>

                                    {/*<div className="small text-gray text-center mt-3">
                                    Image Source: <a href="https://icons8.com/illustrations" rel="noopener nofollow" target="_blank">Icons8</a>
                                </div> */}

                                </div>
                            </div>
                            {/*End Tab Image */}

                            {/*Tab Image */}
                            <div className="tab-pane show fade" id="services-item-4a" role="tabpanel">
                                <div className="services-7-content">

                                    <div className="services-7-image">
                                        <img src="assets/images/services/4.jpg"
                                            alt="Digital Marketing for Green Businesses" />
                                    </div>

                                    {/*<div className="small text-gray text-center mt-3">
                                    Image Source: <a href="https://icons8.com/illustrations" rel="noopener nofollow" target="_blank">Icons8</a>
                                </div> */}

                                </div>
                            </div>
                            {/*End Tab Image */}

                        </div>
                    </div>
                    {/*End Services Images */}

                </div>

            </div>
        </section>

    );
};

export default ServicePage;