

const CallForActionsTwo = () => {
    return (

        <section className="page-section bg-scroll light-content" style={{ backgroundImage: 'url(assets/images/contact_us.webp)' }}>

            {/*Desktop Overlay */}
            <div className="bg-overlay bg-gradient-dark-alpha-2 d-none d-md-block"></div>
            {/*End Desktop Overlay */}

            {/*Mobile Overlay */}
            <div className="bg-overlay bg-dark-1 opacity-09 d-md-none"></div>
            {/*End Mobile Overlay */}

            <div className="container position-relative">

                <div className="row wow fadeInUp">
                    <div className="col-md-7 offset-md-5 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7">

                        <h2 className="section-title mb-40 mb-sm-30">Jumpstart Your Innovation with Renovative Lab</h2>

                        <p className="mb-50 mb-sm-40">
                            At Renovative Lab, we provide the expertise and tools to drive your business forward.
                            Whether you're a startup or an established enterprise, our innovative solutions and
                            sustainable practices ensure your success in a competitive market.
                        </p>

                        <div className="local-scroll mb-70 mb-sm-50">
                            <a href="gradient-contact.html" className="btn btn-mod btn-medium btn-grad btn-round"
                                data-btn-animate="y">Contact us <i
                                    className="mi-arrow-right size-24 align-center"></i></a>
                        </div>

                        {/*Features List */}
                        <div className="row mt-n10">

                            {/*Features List Item */}
                            <div className="col-lg-6 d-flex mt-10">
                                <div className="features-list-icon features-list-grad">
                                    <i className="mi-check"></i>
                                </div>
                                <div className="features-list-text">
                                    Sustainable Technology
                                </div>
                            </div>
                            {/*End Features List Item */}

                            {/*Features List Item */}
                            <div className="col-lg-6 d-flex mt-10">
                                <div className="features-list-icon features-list-grad">
                                    <i className="mi-check"></i>
                                </div>
                                <div className="features-list-text">
                                    Innovative Solutions
                                </div>
                            </div>
                            {/*End Features List Item */}

                            {/*Features List Item */}
                            <div className="col-lg-6 d-flex mt-10">
                                <div className="features-list-icon features-list-grad">
                                    <i className="mi-check"></i>
                                </div>
                                <div className="features-list-text">
                                    Eco-friendly Practices
                                </div>
                            </div>
                            {/*End Features List Item */}

                            {/*Features List Item */}
                            <div className="col-lg-6 d-flex mt-10">
                                <div className="features-list-icon features-list-grad">
                                    <i className="mi-check"></i>
                                </div>
                                <div className="features-list-text">
                                    Tech-driven Growth
                                </div>
                            </div>
                            {/*End Features List Item */}

                        </div>
                        {/*End Features List */}

                    </div>
                </div>


            </div>
        </section>

    );
};

export default CallForActionsTwo;