import React from 'react';
import LazyImage from '../components/lazyImage';

const AboutPage = () => {
    return (

        <section className="page-section bg-gradient-gray-light-2 bg-scroll" id="about">

            <div className="container position-relative">

                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">

                        <div className="wow fadeInUp">

                            <h2 className="section-caption-gradient mb-20 mb-xs-10">About Renovative Lab</h2>

                            <div className="section-line-gradient mx-auto mb-20"></div>

                            <h3 className="section-title mb-40 mb-sm-30">Innovative solutions for a sustainable future
                            </h3>

                        </div>

                        <div className="row text-start text-gray mb-80 mb-sm-60">

                            <div className="col-md-6 mb-sm-20 wow linesAnimInLong" data-splitting="lines">
                                At Renovative Lab, we are dedicated to developing technological solutions that
                                foster sustainable development. Our innovative approach integrates the latest in
                                tech with eco-friendly practices.
                            </div>

                            <div className="col-md-6 wow linesAnimInLong" data-splitting="lines">
                                Our team of experts works tirelessly to create solutions that not only meet the
                                needs of today but also preserve the environment for future generations. Join us in
                                making a positive impact on the world.
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            {/*Images Composition */}
            <div className="possition-relaive">

                {/*Decorative Line */}
                <div className="bg-line-1 z-index-1">
                    <img src="assets/images/about_us/decoration-2.png" alt="decoration" />
                </div>
                {/*End Decorative Line */}

                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">

                            <div className="composition-7">

                                <div className="composition-7-image-1">
                                    <div className="composition-7-border"></div>
                                    <div className="composition-7-inner">
                                     
                                        <LazyImage src="assets/images/about_us/about_us_2.jpg" alt="Description" />
                                    </div>
                                </div>

                                <div className="composition-7-image-2">
                                    <div className="composition-7-border"></div>
                                    <div className="composition-7-inner">
                                      
                                        <LazyImage src="assets/images/about_us/about_us_3.jpg" alt="Description" />
                                    </div>
                                </div>

                                <div className="composition-7-image-3">
                                    <div className="composition-7-border"></div>
                                    <div className="composition-7-inner">
                                      
                                        <LazyImage src="assets/images/about_us/about_us_4.jpg" alt="Description" />
                                    </div>
                                </div>

                                <div className="composition-7-image-4">
                                    <div className="composition-7-border"></div>
                                    <div className="composition-7-inner">
                                     
                                        <LazyImage src="assets/images/about_us/about_us_5.jpg" alt="Description" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*End Images Composition */}

        </section>

    );
};

export default AboutPage;