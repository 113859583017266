import React from 'react';

const HomePage = () => {
    return (


        <section className="home-section light-content parallax-5"
            style={{ backgroundImage: 'url(assets/images/banner/1920X1080.webp' }} id="home">

            {/*Gradient Overlay */}
            <div className="bg-overlay bg-gradient-primary-2 opacity-01"></div>
            {/*End Gradient Overlay */}

            <div
                className="container position-relative min-height-100vh overflow-hidden d-flex align-items-center pt-100 pb-100">

                {/*Home Section Content */}
                <div className="home-content">

                    <h1 className="hs-line-14 text-outline-cont mb-40 mb-sm-20 wow fadeRotateIn">
                        <span className="text-outline">Renovative Lab</span>
                    </h1>

                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <p className="section-descr mb-50 mb-sm-40 wow fadeInUp" data-wow-delay="0.2s">
                                Innovating for a sustainable future with cutting-edge technology solutions.
                            </p>
                        </div>
                    </div>

                    <div className="local-scroll wow fadeInUp wch-unset" data-wow-delay="0.4s">
                        {/*<a href="#portfolio" className="btn btn-mod btn-w btn-border-c btn-small btn-round" data-btn-animate="y">Discover works</a> */}
                        <a href="#about" className="opacity-1 no-hover">

                            <span className="btn btn-mod btn-w btn-border-c btn-small btn-round text-white"
                                data-btn-animate="y">Discover Now</span>
                        </a>
                    </div>

                </div>
                {/*End Home Section Content */}

                {/*Scroll Down */}
                <div className="local-scroll scroll-down-wrap wow fadeInUp" data-wow-offset="0">
                    <a href="#about" className="scroll-down opacity-09"><i
                        className="mi-chevron-down text-gradient"></i><span className="visually-hidden">Scroll
                            Down</span></a>
                </div>
                {/*End Scroll Down */}

            </div>
        </section>




    );
};

export default HomePage;