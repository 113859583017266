

const ContactPage = () => {

    return (
        <section className="page-section" id="contact">
            <div className="container position-relative">

                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">

                        <h2 className="section-caption-gradient mb-20 mb-xs-10">Contact Us</h2>

                        <div className="section-line-gradient mx-auto mb-20"></div>

                        <h3 className="section-title mb-40 mb-sm-30">Say hello, and let’s start a productive
                            collaboration.</h3>

                        <p className="text-gray mb-80 mb-sm-60">
                            Whether you have a question about our services, need assistance, or want to discuss your
                            next project, our team is ready to help.
                        </p>

                    </div>
                </div>

                <div className="row wow fadeInUp">

                    {/*Left Column */}
                    <div className="col-lg-4 mb-md-50 mb-sm-30 position-relative z-index-1">

                        <hr className="mt-0 mb-40 mb-sm-20" />

                        {/*Address */}
                        <div className="contact-item mb-40 mb-sm-20">
                            <div className="ci-icon ci-icon-grad">
                                <i className="mi-location"></i>
                            </div>
                            <h4 className="ci-title">
                                Address
                            </h4>
                            <div className="ci-text">
                                22 Golertek, Mirpur-1, Dhaka-1216, Bangladesh
                            </div>
                          
                        </div>
                        {/*End Address */}

                        <hr className="mt-0 mb-40 mb-sm-20" />

                        {/*Email */}
                        <div className="contact-item mb-40 mb-sm-20">
                            <div className="ci-icon ci-icon-grad">
                                <i className="mi-email"></i>
                            </div>
                            <h4 className="ci-title">
                                Email
                            </h4>
                            <div className="ci-text">
                                info@renovativelab.com
                            </div>
                           
                        </div>
                        {/*End Email */}

                        <hr className="mt-0 mb-40 mb-sm-20" />

                        {/*Phone */}
                        <div className="contact-item mb-40 mb-sm-20">
                            <div className="ci-icon ci-icon-grad">
                                <i className="mi-mobile"></i>
                            </div>
                            <h4 className="ci-title">
                                Phone
                            </h4>
                            <div className="ci-text">
                                +880 1404224260
                            </div>
                          
                        </div>
                        {/*End Phone */}

                        <hr className="mt-0 mb-0" />

                    </div>
                    {/*End Left Column */}

                    {/*Right Column */}
                    <div className="col-lg-8 col-xl-7 offset-xl-1 d-flex align-items-stretch">
                        <div className="bg-gradient-light-2 round overflow-hidden w-100 px-4 py-5 p-sm-5">

                            <div className="row">
                                <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                                    <h4 className="section-descr-medium mt-n10 mb-30 text-center">Drop us a line to get
                                        your next project started. We'd love to hear from you.</h4>
                                </div>
                            </div>

                            {/*Contact Form */}
                            <form className="form contact-form" id="contact_form">

                                <div className="row">
                                    <div className="col-md-6">

                                        {/*Name */}
                                        <div className="form-group">
                                            <label for="name">Name</label>
                                            <div className="input-grad-wrap round">
                                                <input type="text" name="name" id="name"
                                                    className="input-md input-grad round form-control"
                                                    placeholder="Enter your name" pattern=".{3,100}" required
                                                    aria-required="true" />
                                            </div>
                                        </div>
                                        {/*End Name */}

                                    </div>

                                    <div className="col-md-6">

                                        {/*Email */}
                                        <div className="form-group">
                                            <label for="email">Email</label>
                                            <div className="input-grad-wrap round">
                                                <input type="email" name="email" id="email"
                                                    className="input-md input-grad round form-control"
                                                    placeholder="Enter your email" pattern=".{5,100}" required
                                                    aria-required="true" />
                                            </div>
                                        </div>
                                        {/*End Email */}

                                    </div>
                                </div>

                                {/*Message */}
                                <div className="form-group">
                                    <label for="message">Message</label>
                                    <div className="input-grad-wrap round">
                                        <textarea name="message" id="message"
                                            className="input-md input-grad round form-control" style={{ height: '120px' }}
                                            placeholder="Enter your message"></textarea>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-6 col-xl-7 d-flex align-items-center">

                                        {/*Inform Tip */}
                                        <div className="form-tip w-100 pt-3">
                                            <i className="icon-info size-16"></i>
                                            All the fields are required. By sending the form you agree to the <a
                                                href="/terms-and-conditions">Terms & Conditions</a> and <a href="/privacy-policy">Privacy Policy</a>.
                                        </div>
                                        {/*End Inform Tip */}

                                    </div>

                                    <div className="col-md-6 col-xl-5 mt-sm-20">

                                        {/*Send Button */}
                                        <div className="pt-3 text-md-end">
                                            <button className="submit_btn btn-mod btn-medium btn-grad btn-round"
                                                data-btn-animate="y" id="submit_btn" aria-controls="result">
                                                Send Message
                                            </button>
                                        </div>
                                        {/*End Send Button */}

                                    </div>

                                </div>

                                <div id="result" role="region" aria-live="polite" aria-atomic="true"></div>

                            </form>
                            {/*End Contact Form */}

                        </div>
                    </div>
                    {/*End Right Column */}

                </div>

            </div>
        </section>
    );
};

export default ContactPage;