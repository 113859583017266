

const CallForActionsOne = () => {
    return (

        <section className="full-wrapper">
            <div className="page-section bg-dark-1 bg-dark-alpha-70 bg-scroll light-content round-large overflow-hidden pt-0 pb-0 "
                style={{ backgroundImage: 'url(assets/images/services/s-b-2.jpg)' }}>
                <div className="page-section container position-relative scroll-nav-invisible">


                    <div className="row text-center">
                        <div className="col-md-8 offset-md-2">

                            <h4 className="section-title mb-20 text-white">Exclusive Creative Services</h4>
                            <h5 className="section-subtitle mb-20">Enhance your brand with sustainable, innovative
                                solutions.
                            </h5>
                            <p className="section-descr-medium mb-50 mb-sm-40">
                                Our expert team crafts tailored strategies to elevate your brand’s presence while
                                integrating eco-friendly practices for long-term success.
                            </p>
                            <div className="local-scroll">
                                <a href="#contact" className="btn btn-mod btn-medium btn-grad btn-round"
                                    data-btn-animate="y">Contact Us<i
                                        className="mi-arrow-right size-24 align-center"></i></a>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>

    );
};

export default CallForActionsOne;