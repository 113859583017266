import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import HomePage from './pages';
import AboutPage from './pages/about';
import ServicePage from './pages/service';
import CallForActionsOne from './pages/callForActionsOne';
import StepsPage from './pages/steps';
import CallForActionsTwo from './pages/callForActionsTwo';
import ContactPage from './pages/contact';


function App() {

  useEffect(() => {
    // Initialize Google Analytics with your tracking ID
    ReactGA.initialize('G-SR8C5VLZY4');

    // Report a pageview for the initial page load
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);
  return (
    <>

      {/*Page Wrap */}
      <div className="page" id="top">

        {/*Navigation Panel */}
        <nav className="main-nav dark transparent light-after-scroll stick-fixed wow-menubar wch-unset">
          <div className="main-nav-sub container">

            {/*Logo  (* Add your text or image to the link tag. Use SVG or PNG image format. 
    If you use a PNG logo image, the image resolution must be equal 200% of the visible logo
    image size for support of retina screens. See details in the template documentation. *) */}
            <div className="nav-logo-wrap position-static pe-lg-4 me-lg-5 local-scroll">
              <a href="/" className="logo">
                <img src="assets/images/logo_text_rl.png" alt="Your Company Logo" width="184" height="40"
                  className="logo-white scrolled-hidden" />
                <img src="assets/images/logo.png" alt="Your Company Logo" width="40" height="40"
                  className="logo-dark scrolled-visible" />
              </a>
            </div>

            {/*Mobile Menu Button */}
            <div className="mobile-nav" role="button" tabindex="0">
              <i className="mobile-nav-icon"></i>
              <span className="visually-hidden">Menu</span>
            </div>

            {/*Main Menu */}
            <div className="inner-nav desktop-nav">
              <ul className="clearlist local-scroll justify-content-start">
                <li><a href="/" className="active" data-btn-animate="y">Home</a></li>
                <li><a href="#about" data-btn-animate="y">About</a></li>
                <li><a href="#services" data-btn-animate="y">Services</a></li>
                <li><a href="#contact" data-btn-animate="y">Contact</a></li>
              </ul>

              <ul className="items-end clearlist local-scroll">


                <li>
                  <a href="#contact" className="opacity-1 no-hover">
                    {/*Button visible before body scrolling */}
                    <span className="btn btn-mod btn-border-w-light btn-small btn-round scrolled-hidden"
                      data-btn-animate="y">Get in touch</span>
                    {/*Button visible after body scrolling */}
                    <span className="btn btn-mod btn-w btn-border-c btn-small btn-round scrolled-visible"
                      data-btn-animate="y">Get in touch</span>
                  </a>
                </li>

              </ul>

            </div>
            {/*End Main Menu */}

          </div>
        </nav>
        {/*End Navigation Panel */}

        <main id="main">
          <HomePage />
          <AboutPage />
          <ServicePage />
          <CallForActionsOne />
          <StepsPage />
          <CallForActionsTwo />
          <ContactPage />
        </main >

        {/*Footer */}
        <footer className="page-section footer bg-dark-1 light-content overflow-hidden pb-30">
          <div className="container position-relative">
            <div className="row pb-120 pb-sm-80 pb-xs-50">
              <div className="col-lg-4 mb-md-50">
                <div className="mb-10">
                  <img src="assets/images/logo.png" alt="Renovative Lab Logo" width="70" height="70" />
                </div>
                <h2 className="section-title-small mt-n10 mb-30">Get started with Renovative Lab</h2>
                <p className="text-gray mb-40">
                  Join us in our mission to innovate for a sustainable future. Contact us to learn more about
                  our services and projects.
                </p>
                <a href="#contact" className="btn btn-mod btn-medium btn-grad btn-round"
                  data-btn-animate="y">Contact us <i className="mi-arrow-right size-24 align-center"></i></a>
              </div>
              <div className="col-lg-7 offset-lg-1">
                <div className="row mt-n30">
                  <div className="col-sm-4 mt-30">
                    <h3 className="fw-title">Company</h3>
                    <ul className="fw-menu clearlist local-scroll">
                      <li><a href="#about">About</a></li>
                      <li><a href="#services">Services</a></li>
                      <li><a href="#contact">Contact</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-4 mt-30">
                    <h3 className="fw-title">Social Media</h3>
                    <ul className="fw-menu clearlist">
                      <li><a href="https://www.facebook.com/people/Renovative-Lab/61564905255638/"
                        rel="noreferrer" target="_blank"><i className="fa-facebook" style={{paddingRight:5}}></i>
                        Facebook</a></li>
                   
                      <li><a href="https://www.linkedin.com/company/renovative-lab/"
                        rel="noreferrer" target="_blank"><i className="fa-linkedin" style={{paddingRight:5}}></i>
                        LinkedIn</a></li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div className="row text-gray">
              <div className="col-md-4 col-lg-3">
                <b>© Renovative Lab 2024.</b>
              </div>
              <div className="col-md-7 offset-md-1 offset-lg-2 clearfix">
                <b>Based in Dhaka, Bangladesh.</b>
                <a href="#top" className="link-to-top gradient"><i className="mi-arrow-up size-24"></i><span
                  className="visually-hidden">Scroll to top</span></a>
              </div>
            </div>
          </div>
        </footer>
        {/*End Footer */}
      </div>
      {/*End Page Wrap */}
    </>
  );
}

export default App;
